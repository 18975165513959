<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout pt-2 wrap px-2 justify-center>
                <v-flex>
                  <v-card>
                    <v-layout py-2 wrap>
                      <v-flex xs12 md12 text-center>
                        <span style="font-size:20px;font-weight:500;">Website Logs</span>
                      </v-flex>
                    </v-layout>
                    <v-layout pt-2 wrap justify-center>
                      <v-flex text-center>
                        <span style="font-size:16px;font-weight:600">Filter</span>
                      </v-flex>
                    </v-layout>
                    <v-layout px-2 wrap justify-center>
                      <v-flex xs6 sm6 md3>
                        <v-menu
                          v-model="fromlogpicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="logfrom"
                              clearable
                              @click:clear="logfrom = null"
                              label="From"
                              prepend-icon="mdi-calendar"
                              color="#26af82"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="logfrom"
                            :max="logto"
                            @input="fromlogpicker = false"
                            color="#26af82"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs6 sm6 md3>
                        <v-menu
                          v-model="tologpicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="logto"
                              clearable
                              @click:clear="logto = null"
                              label="To"
                              prepend-icon="mdi-calendar"
                              color="#26af82"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="logto"
                            :min="logfrom"
                            @input="tologpicker = false"
                            color="#26af82"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs6 sm4 md3 text-center>
                        <v-text-field
                          v-model="loguser"
                          label="Username"
                          clearable
                          @click:clear="loguser = null"
                          color="#26af82"
                          prepend-icon="mdi-account"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6 sm4 md3 text-center align-self-center>
                        <v-btn
                          @click="getDocuments"
                          text
                          color="blue"
                          class="text-capitalize"
                        >Request Document</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout pa-2 pt-4 wrap justify-center>
                <v-flex xs12 sm12 md12>
                  <v-data-table
                    :headers="headers"
                    :items="logs"
                    :sort-desc="[false, true]"
                    hide-default-footer
                    dense
                    :loading="appLoading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                    color="#26af82"
                  >
                    <template v-slot:item.createddate="{ item }">
                      <span style="font-size:14px;">{{formatDate(item.createddate)}}</span>
                    </template>
                    <template v-slot:item.from="{ item }">
                      <span style="font-size:14px;">{{formatDate(item.from)}}</span>
                    </template>
                    <template v-slot:item.to="{ item }">
                      <span style="font-size:14px;">{{formatDate(item.to)}}</span>
                    </template>

                    <template v-slot:item._id="{ item }">
                      <v-layout wrap justify-center>
                        <v-flex>
                          <a :href="baseURL+'/d/'+item.filename" target="_blank" download>
                            <v-btn small text color="#26af82" block class="text-capitalize">Download</v-btn>
                          </a>
                        </v-flex>
                        <v-flex>
                          <v-btn
                            small
                            text
                            @click="deleteDoc(item)"
                            color="red"
                            block
                            class="text-capitalize"
                          >Delete</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      search: null,
      headers: [
        { text: "File Name", align: "center", value: "filename" },
        { text: "Created Date", align: "center", value: "createddate" },
        { text: "From", align: "center", value: "from" },
        { text: "To", align: "center", value: "to" },
        { text: "Action", align: "center", value: "_id" }
      ],
      logs: [],
      itemsPerPage: 10,
      page: 1,
      totalpages: 0,
      ItemsCount: [5, 10, 25, 50, 500],
      logfrom: null,
      logto: null,
      loguser: null,
      fromlogpicker: false,
      tologpicker: false,
      email: null
    };
  },
  beforeMount() {
    var startdate;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    startdate = yyyy + "-" + mm + "-" + "01";
    this.logfrom = startdate;
    this.logto = today;
    console.log(today);
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/docs/getlist",
        timeout: 10000,
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          console.log(response);
          this.logs = response.data.data;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    deleteDoc(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/docs/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: {
          id: item._id
        }
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getData();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    getDocuments() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/log/pdf",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          from: this.logfrom,
          to: this.logto,
          username: this.loguser
        }
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },

    formatDate(item) {
      var dt, day, year, strTime;
      dt = new Date(item);
      day = dt.getDate();
      year = dt.getFullYear();
      dt = dt.toString();
      strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
.centered-input input {
  text-align: center;
  height: 20px;
}
</style>