var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":false}}),_c('v-snackbar',{attrs:{"color":"#00a972","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(" "+_vm._s(_vm.msg)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","pa-0":""}},[_c('v-card',{attrs:{"id":"app_height","outlined":"","tile":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","pa-8":""}},[_c('v-layout',{attrs:{"pt-2":"","wrap":"","px-2":"","justify-center":""}},[_c('v-flex',[_c('v-card',[_c('v-layout',{attrs:{"py-2":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","text-center":""}},[_c('span',{staticStyle:{"font-size":"20px","font-weight":"500"}},[_vm._v("Website Logs")])])],1),_c('v-layout',{attrs:{"pt-2":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-center":""}},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v("Filter")])])],1),_c('v-layout',{attrs:{"px-2":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":"","sm6":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"From","prepend-icon":"mdi-calendar","color":"#26af82","readonly":""},on:{"click:clear":function($event){_vm.logfrom = null}},model:{value:(_vm.logfrom),callback:function ($$v) {_vm.logfrom=$$v},expression:"logfrom"}},on))]}}]),model:{value:(_vm.fromlogpicker),callback:function ($$v) {_vm.fromlogpicker=$$v},expression:"fromlogpicker"}},[_c('v-date-picker',{attrs:{"max":_vm.logto,"color":"#26af82"},on:{"input":function($event){_vm.fromlogpicker = false}},model:{value:(_vm.logfrom),callback:function ($$v) {_vm.logfrom=$$v},expression:"logfrom"}})],1)],1),_c('v-flex',{attrs:{"xs6":"","sm6":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"clearable":"","label":"To","prepend-icon":"mdi-calendar","color":"#26af82","readonly":""},on:{"click:clear":function($event){_vm.logto = null}},model:{value:(_vm.logto),callback:function ($$v) {_vm.logto=$$v},expression:"logto"}},on))]}}]),model:{value:(_vm.tologpicker),callback:function ($$v) {_vm.tologpicker=$$v},expression:"tologpicker"}},[_c('v-date-picker',{attrs:{"min":_vm.logfrom,"color":"#26af82"},on:{"input":function($event){_vm.tologpicker = false}},model:{value:(_vm.logto),callback:function ($$v) {_vm.logto=$$v},expression:"logto"}})],1)],1),_c('v-flex',{attrs:{"xs6":"","sm4":"","md3":"","text-center":""}},[_c('v-text-field',{attrs:{"label":"Username","clearable":"","color":"#26af82","prepend-icon":"mdi-account"},on:{"click:clear":function($event){_vm.loguser = null}},model:{value:(_vm.loguser),callback:function ($$v) {_vm.loguser=$$v},expression:"loguser"}})],1),_c('v-flex',{attrs:{"xs6":"","sm4":"","md3":"","text-center":"","align-self-center":""}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"blue"},on:{"click":_vm.getDocuments}},[_vm._v("Request Document")])],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"pa-2":"","pt-4":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.logs,"sort-desc":[false, true],"hide-default-footer":"","dense":"","loading":_vm.appLoading,"loading-text":"Loading... Please wait","color":"#26af82"},scopedSlots:_vm._u([{key:"item.createddate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.formatDate(item.createddate)))])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.formatDate(item.from)))])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.formatDate(item.to)))])]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',[_c('a',{attrs:{"href":_vm.baseURL+'/d/'+item.filename,"target":"_blank","download":""}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"small":"","text":"","color":"#26af82","block":""}},[_vm._v("Download")])],1)]),_c('v-flex',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"small":"","text":"","color":"red","block":""},on:{"click":function($event){return _vm.deleteDoc(item)}}},[_vm._v("Delete")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }